.headerContainer {
  padding: 0 24px;
  height: 75.23px;
}

@media screen and (max-width: 768px) {
  .custom_filter_bulk_import > div {
    position: relative !important;
  }
  .custom_filter_bulk_import > div > :nth-of-type(2) {
    top: -20px !important;
  }
}
