.modal_style {
  border-radius: 12px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
}

.header_modal {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 462px;
}

.close_icon {
  cursor: pointer;
}

.body_modal {
  max-width: 462px;
  padding: 24px 0px;
}

.body_modal_text {
  color: #272522;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.28px;
  opacity: 0.5;
}

.info_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.info_box {
  min-width: 223px;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
}

.success_box {
  background: #e5fff7;
  color: #096;
}

.error_box {
  background: #fbf4f4;
  color: #bf2600;
}

.warning_box {
  background: #fef7eb;
  color: #960;
}

.footer_container {
  padding: 16px;
  display: flex;
  gap: 16px;
}

.button_one_icon {
  width: 18px !important;
  height: 18px !important;
}

.button_one_style {
  border-radius: 5px;
  background: #493ab1;
  width: 100%;
  color: #fff;
  text-align: right;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.button_class_name {
  width: 100% !important;
}

.button_wrapper_style {
  width: 100%;
}

.info_warning_style {
  margin-top: 16px;
  display: flex;
  gap: 8px;
  padding: 10px 12px;
  border-radius: 5px;
  background-color: #fff7e5;
}

.info_warning_icon_style {
  color: #cc8800 !important;
  width: 18px !important;
  height: 18px !important;
}

.info_warning_text_style {
  color: #272522;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.24px;
}

@media screen and (max-width: 768px) {
  .info_box {
    width: 100%;
  }
}
