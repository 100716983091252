.mainWrapper {
  padding-left: 60px;
  padding-right: 175px;
}

.uploadDivWrapper {
  border-bottom: 1px solid #eeecf8;
  padding-top: 8px;
  padding-bottom: 32px;
}

.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 12px;
}
.infoSubheading {
  display: flex;
  align-items: center;
}
.bold {
  font-weight: 700;
}
.tooltip {
  background: #20194d;
  border-radius: 8px;
  padding: 10px 6px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* or 142% */

  letter-spacing: 0.02em;
}
.toolbarWrapper {
  background-color: #ffffff !important;
}
.offeringSelectWrapper {
  width: 256px;
}
.uploadButtonWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  /* background: #493AB1; */
}

.subHeading {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.02em;
  opacity: 0.6;
}

.blueHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.02em;

  color: #493ab1;
  margin-top: 24px;
  margin-bottom: 8px;
}

.imgWrapper {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 14px;
}

.buttonWrapper {
  border-radius: 5px !important;
  margin-top: 24px !important;
}

.buttonWrapper:hover {
  color: #ffffff !important;
}

.buttonWrapperPrimary {
  color: #ffffff !important;
  border-radius: 5px !important;
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.buttonWrapperPrimary:hover {
  color: #ffffff !important;
}

.linkClass {
  font-style: normal !important;
  text-decoration: none !important;
  font-weight: 700;
  font-size: 18px !important;
  line-height: 21.98px;
  padding: 6px 0;

  font-style: normal !important;
  color: #272522 !important;
}

.wrapperClass {
  margin-left: 28px !important;
}

.errorMsgCtn {
  border-radius: 4px;
  background: #f2d6d7;
  padding: 10px 21px;
  width: 340px;
  margin: 16px 0px;
}

.errorMsgText {
  color: #bf0c0f;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0 !important;
}
