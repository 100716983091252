.title_style {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title_text_style {
  color: #272522;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.title_icon_style {
  fill: #bf2600 !important;
}

.content_style {
  color: #272522;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.28px;
  width: 392px;
  margin-top: 16px;
  margin-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .content_style {
    width: 100%;
  }
}
